import React, { useState, useCallback } from 'react';
import { useFlowchartStore } from '../../../Store/FlowchartStore/FlowchartStore';
import ReactFlow, {
  addEdge,
  Controls,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from 'react-flow-renderer';
import DecisionNode from './DecisionNode'; // Import the updated DecisionNode component
import { FaLink, FaTrash, FaSave } from 'react-icons/fa';
import { FiSquare } from 'react-icons/fi';
import { FaRegCircle } from "react-icons/fa";
import { GoDiamond } from "react-icons/go";
import Swal from 'sweetalert2';
const nodeTypes = {
  custom: DecisionNode,
};

const initialElements = [
  { id: '1', type: 'custom', data: { label: 'Start', type: 'start', shape: 'oval' }, position: { x: 250, y: 0 } },
  { id: '2', type: 'custom', data: { label: 'Decision 1', type: 'decision', shape: 'diamond' }, position: { x: 250, y: 100 } },
  { id: '3', type: 'custom', data: { label: 'Task 1', type: 'task', shape: 'rectangle' }, position: { x: 100, y: 200 } },
  { id: '4', type: 'custom', data: { label: 'Task 2', type: 'task', shape: 'rectangle' }, position: { x: 400, y: 200 } },
  { id: 'e1-2', source: '1', target: '2', animated: true },
  { id: 'e2-3', source: '2', target: '3', animated: true, label: 'Yes' },
  { id: 'e2-4', source: '2', target: '4', animated: true, label: 'No' },
];

const Decision = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialElements.filter(el => !el.source && !el.target));
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialElements.filter(el => el.source && el.target));
  const { createChart } = useFlowchartStore();
  const [name, setName] = useState('');
  const [selectedShape, setSelectedShape] = useState('rectangle'); // State for selected shape

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);
  const projectId = localStorage.getItem('ProjectId');
  const saveToAPI = async () => {
    try {
      const flowchart = { nodes, edges };
      await createChart({ flowcharts: flowchart, projectId, type: 'decision', name: name });
       Swal.fire('Success!', 'Flowchart saved successfully!', 'success'); // SweetAlert for success
    } catch (error) {
      console.error('Failed to save flowchart to API', error);
      Swal.fire('Error!', 'Failed to save flowchart.', 'error'); // SweetAlert for error
    }
  };


  const addNode = () => {
    const newNode = {
      id: `${nodes.length + 1}`,
      type: 'custom',
      data: { label: `Node ${nodes.length + 1}`, type: 'task', shape: selectedShape }, // Use selected shape
      position: { x: 100, y: nodes.length * 100 },
    };
    setNodes((nds) => nds.concat(newNode));
  };

  const addEdgeDynamically = () => {
    if (nodes.length < 2) {
      alert('Add at least two nodes to create an edge.');
      return;
    }
    const newEdge = {
      id: `e${nodes.length - 1}-${nodes.length}`,
      source: `${nodes.length - 1}`,
      target: `${nodes.length}`,
      animated: true,
    };
    setEdges((eds) => eds.concat(newEdge));
  };

  const removeSelectedElements = () => {

    const selectedNodes = nodes.filter((node) => node.selected);
    const selectedEdges = edges.filter((edge) => edge.selected);

    if (selectedNodes.length === 0 && selectedEdges.length === 0) {
      alert('Select a node or edge to remove.');
      return;
    }
    const selectedNodeIds = new Set(selectedNodes.map((node) => node.id));
    const updatedNodes = nodes.filter((node) => !node.selected);

    const updatedEdges = edges.filter(
      (edge) =>
        !selectedEdges.includes(edge) &&
        !selectedNodeIds.has(edge.source) &&
        !selectedNodeIds.has(edge.target)
    );

    setNodes(updatedNodes);
    setEdges(updatedEdges);
  };

  return (
    <div style={{ height: '100vh', position: 'relative', overflow: 'hidden' }}>
      <style>
        {`
          .react-flow__attribution {
            display: none;
          }
        `}
      </style>

      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          fitView
          nodeTypes={nodeTypes}
          deleteKeyCode={46} // 'delete'-key
        >
          <Controls />
        </ReactFlow>
      </ReactFlowProvider>
      <div style={{ position: 'absolute', top: 10, left: 10, zIndex: 9999, display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <input
          type="text"
          placeholder="Enter name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </div>
      <div style={{ position: 'absolute', top: 10, right: 10, zIndex: 9999, display: 'flex', gap: '10px' }}>


        <button
          onClick={() => addNode('rectangle')}
          style={{
            padding: '8px',
            borderRadius: '50%',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
          title="Add Rectangle Node"
        >
          <FiSquare size={20} />
        </button>

        <button
          onClick={() => addNode('oval')}
          style={{
            padding: '8px',
            borderRadius: '50%',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
          title="Add Oval Node"
        >
          <FaRegCircle size={20} />
        </button>

        <button
          onClick={() => addNode('diamond')}
          style={{
            padding: '8px',
            borderRadius: '50%',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
          title="Add Diamond Node"
        >
          <GoDiamond size={20} />
        </button>

        <button
          onClick={addEdgeDynamically}
          style={{
            padding: '8px',
            borderRadius: '50%',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
          title="Add Edge"
        >
          <FaLink size={20} />
        </button>

        <button
          onClick={removeSelectedElements}
          style={{
            padding: '8px',
            borderRadius: '50%',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
          title="Remove Selected"
        >
          <FaTrash size={20} />
        </button>

        <button
          onClick={saveToAPI}
          style={{
            padding: '8px',
            borderRadius: '50%',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
          title="Save Flowchart"
        >
          <FaSave size={20} />
        </button>
      </div>
    </div>
  );
};

export default Decision;
