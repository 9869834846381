import React, { useState, useEffect } from "react";
import { IoMdAdd } from "react-icons/io";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useBoardStore } from "../Store/BoardStore/BoardStore";
import { useMemberStore } from "../Store/MemberStore/MemberStore";
import { useFlowchartStore } from '../Store/FlowchartStore/FlowchartStore';
import { FaTrash } from 'react-icons/fa';
import { useAccountStore } from "../Store/AccountStore/AccountStore";
const CreateBoard = () => {
  const [drawings, setDrawings] = useState([]);
  const [members, setMembers] = useState([]);
  const [charts, setCharts] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({
    ownership: false,
    lastOpened: false,
    assigned: false,
  })
  const [filters, setFilters] = useState({
    ownership: 'anyone',
    lastOpened: false,
    assigned: false // New filter for assigned boards
  });
  const FilterDropdown = ({  value, options, onChange, isOpen, toggleDropdown }) => {
    return (
      <div className="create-board-filter-dropdown">
        <div className="create-board-filter-button" onClick={toggleDropdown}>
          <span> {value}</span>
          <div className="create-board-filter-icon">
            <MdKeyboardArrowDown size={20} />
          </div>
        </div>
        {isOpen && (
          <ul className="create-board-filter-options">
            {options.map((option) => (
              <li className=" cursor-pointer text-center bg-gray-300 hover:bg-gray-500" key={option} onClick={() => onChange(option)}>
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };
  const navigate = useNavigate();
  const { createBoard, getBoard, deleteBoard } = useBoardStore();
  const { getMemberBoard } = useMemberStore();
  const { getChart, deleteChart } = useFlowchartStore();
  const { userProfile } = useAccountStore()
  const userId = localStorage.getItem("userId");
  const [load, setLoad] = useState(true)
  const [user, setUser] = useState(undefined)

  const array = [
    { src: "/Screenshot (13).png", alt: "image1", text: "Process Flowchart", route: "/process" },
    { src: "/Screenshot (14).png", alt: "image2", text: "Program Flowchart", route: "/program" },
    { src: "/Screenshot (15).png", alt: "image3", text: "Swimlance Flowchart", route: "/swimlance" },
    { src: "/Screenshot (16).png", alt: "image4", text: "System Flowchart", route: "/system" },
    { src: "/Screenshot (17).png", alt: "image5", text: "Workflow Flowchart", route: "/workflow" },
    { src: "/Screenshot (18).png", alt: "image6", text: "EPC Flowchart", route: "/EPC" },
    { src: "/Screenshot (19).png", alt: "image7", text: "Decision Flowchart", route: "/decision" },
    { src: "/Screenshot (20).png", alt: "image8", text: "Dataflow Flowchart", route: "/dataflow" },
    // { src: "/Screenshot (21).png", alt: "image9", text: "Cross Functional Flowchart", route: "/crossfuntional" },
    { src: "/Screenshot (22).png", alt: "image10", text: "BPMN Flowchart", route: "/BPMN" },
  ];


  // Function to fetch drawings by user ID
  const fetchDrawings = async () => {
    try {
      const data = await getBoard();
      setDrawings(data || []); // Ensure drawings is always an array
    } catch (error) {
      console.error("Error fetching drawings:", error);
    }
  };

  useEffect(() => {
    fetchDrawings();
  }, []);






  const fetchMembers = async () => {
    try {
      const helo = await getMemberBoard();

      setMembers(Array.isArray(helo) ? helo : []);
    } catch (err) {
      console.error("Error fetching members:", err);
    }
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  const fetchChart = async () => {
    try {
      const chart = await getChart();
      setCharts(chart || []); // Ensure charts is always an array
    } catch (error) {
      console.error("Error fetching flowcharts:", error);
    }
  };

  useEffect(() => {
    fetchChart();
  }, []);

  const toggleDropdown = (type) => {
    setDropdownOpen((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const handleFilterChange = (type, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: value,
    }));
    toggleDropdown(type); // Close the dropdown after selecting
  };


  const handleDelete = async (boardId) => {
    try {
      await deleteBoard(boardId);
      await fetchDrawings(); // Refresh the list of drawings after deletion
    } catch (error) {
      console.error("Error deleting board:", error);
    }
  };

  const handleDeleteChart = async (chartId) => {
    try {
      await deleteChart(chartId);
      await fetchChart(); // Refresh the list of drawings after deletion
    } catch (error) {
      console.error("Error deleting board:", error);
    }
  };

  const handleCreateBoard = async () => {
    const projectId = localStorage.getItem('ProjectId');
    try {
      const data = await createBoard({
        paths: [],
        user: userId,
        projectId,
        updatedBy: userId,
      });

      if (data && data._id) {
        window.open("/whiteboard/" + data._id, "_blank");
        localStorage.setItem('drawingId', data._id);
      } else {
        console.error("Failed to retrieve the board ID from the response.");
      }
      fetchDrawings();
    } catch (error) {
      console.error("Error creating board:", error);
    }
  };

  const filteredDrawings = (drawings || []).filter(drawing => {
    // Ownership filter: if "Owned by anyone", do not filter by ownership
    if (filters.ownership === 'anyone' && !drawing.owned) {
      return false;
    }
    // Last opened filter: if "Recently opened", ensure lastOpened is true
    if (filters.lastOpened && !drawing.lastOpened) {
      return false;
    }
    // Assigned to me filter: ensure assignedTo exists and is an array, then check if it includes userId
    if (filters.assigned && (!Array.isArray(drawing.assignedTo) || !drawing.assignedTo.includes(userId))) {
      return false;
    }
    return true;
  });

  const filteredBoard = Array.isArray(members) ? members.filter(member => {
    // Ownership filter
    if (filters.ownership === 'anyone' && !member.owned) {
      return false;
    }
    // Last opened filter
    if (filters.lastOpened && !member.lastOpened) {
      return false;
    }
    // Assigned to me filter: ensure assignedTo exists and is an array
    if (filters.assigned && (!Array.isArray(member.assignedTo) || !member.assignedTo.includes(userId))) {
      return false;
    }
    return true;
  }) : [];

  const filteredCharts = (charts || []).filter(chart => {
    // Ownership filter
    if (filters.ownership === 'anyone' && !chart.owned) {
      return false;
    }
    // Last opened filter
    if (filters.lastOpened && !chart.lastOpened) {
      return false;
    }
    // Assigned to me filter: ensure assignedTo exists and is an array
    if (filters.assigned && (!Array.isArray(chart.assignedTo) || !chart.assignedTo.includes(userId))) {
      return false;
    }
    return true;
  });



  // const handleFilterChange = (type) => {
  //   setFilters(prevFilters => ({
  //     ...prevFilters,
  //     [type]: type === 'lastOpened'
  //       ? !prevFilters.lastOpened
  //       : type === 'assigned'
  //         ? !prevFilters.assigned
  //         : (prevFilters[type] === 'me' ? 'owned' : 'me')
  //   }));
  // };

  // Handler to navigate based on the clicked template
  const handleTemplateClick = (route) => {
    if (route) {
      window.open(route, '_blank')
    }
    fetchChart();
  };

  // if (load) {
  //   return (
  //     <>
  //       Loading...
  //     </>
  //   )
  // } else {
  return (
    <>
      <div className="mt-4 space-y-4">
        <h1 className="create-board-title Reshidden">Create a board</h1>
        <div className="create-board-container">
          <div className="create-board-header">
            <p className="create-board-subtitle">Recommended templates</p>
            <p className="create-board-show-all" style={{ cursor: 'pointer' }} onClick={() => setShowAll(!showAll)}>
              {showAll ? "Show Less" : "Show All"}
            </p>
          </div>
          <div className="create-board-grid">
            <div className="md:mr-2">
              <div className="create-board-new">
                <div className="create-board-new-icon" onClick={handleCreateBoard}>
                  <IoMdAdd size={40} />
                </div>
              </div>
              <p className="create-board-new-text">New Board</p>
            </div>
            <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 gap-4">
              {array.slice(0, showAll ? array.length : 4).map((item, index) => (
                <div key={index} className="w-full">
                  <img
                    src={item.src}
                    alt={item.alt}
                    className="create-board-item-image create-board-item"
                    style={{ height: "142px", borderRadius: "9px", cursor: 'pointer' }}
                    onClick={() => handleTemplateClick(item.route)}
                  />
                  <p className="create-board-item-text">{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="create-board-filter-container">
        <h1 className="create-board-filter-title">Team Board</h1>
        <div className="create-board-filter-buttons " style={{marginTop:'20px'}}>


          <FilterDropdown
            label="Ownership"
            value={filters.ownership === 'anyone' ? 'Owned by anyone' : 'Owned by me'}
            options={['Owned by me', 'Owned by anyone']}
            isOpen={dropdownOpen.ownership}
            toggleDropdown={() => toggleDropdown('ownership')}
            onChange={(value) => handleFilterChange('ownership', value === 'Owned by anyone' ? 'anyone' : 'me')}
            style={{ cursor: 'pointer' }} // Adding cursor pointer style
          />

          <FilterDropdown
            label="Last Opened"
            value={filters.lastOpened ? 'Recently opened' : 'Not opened recently'}
            options={['Recently opened', 'Not opened recently']}
            isOpen={dropdownOpen.lastOpened}
            toggleDropdown={() => toggleDropdown('lastOpened')}
            onChange={(value) => handleFilterChange('lastOpened', value === 'Recently opened')}
            style={{ cursor: 'pointer' }}
          />

          <FilterDropdown
            label="Assigned"
            value={filters.assigned ? 'Assigned to me' : 'All boards'}
            options={['Assigned to me', 'All boards']}
            isOpen={dropdownOpen.assigned}
            toggleDropdown={() => toggleDropdown('assigned')}
            onChange={(value) => handleFilterChange('assigned', value === 'Assigned to me')}
            style={{ cursor: 'pointer' }}
          />


        </div>
      </div>
      {/* <div className="create-board-filter-container">
        <h1 className="create-board-filter-title">Team Board</h1>
        <div className="create-board-filter-buttons">
          <div className="create-board-filter-button" onClick={() => handleFilterChange('ownership')}>
            <span>{filters.ownership === 'me' ? 'Owned by me' : 'Owned by anyone'}</span>
            <div className="create-board-filter-icon">
              <MdKeyboardArrowDown size={20} />
            </div>
          </div>
          <div className="create-board-filter-button" onClick={() => handleFilterChange('lastOpened')}>
            <span>Last opened</span>
            <div className="create-board-filter-icon">
              <MdKeyboardArrowDown size={20} />
            </div>
          </div>
          <div className="create-board-filter-button" >
            <span>{filters.assigned ? 'Assigned to me' : 'All boards'}</span> 
            <div className="create-board-filter-icon">
              <MdKeyboardArrowDown size={20} />
            </div>
          </div>
        </div>

      </div> */}

      <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 gap-4 mt-4 rounded">
        {(filteredDrawings.length === 0 && filteredBoard.length === 0) ? (
          <div className="col-span-4 text-center text-gray-500 text-sm">
            No Board found
          </div>
        ) : (
          <>
            {filters.assigned ? (
              filteredBoard.map((member, index) => (
                <div key={member.boardId._id} className="relative border border-gray-300 rounded-lg overflow-hidden">
                  <Link to={`/whiteboard/${member.boardId._id}`} target="_blank">
                    <img src={member.thumbnail || "/images.jpeg"} alt={`Board ${index + 1}`} className="w-full h-32 object-cover" />
                    <div className="flex items-center justify-center mt-2">
                      <p className="text-xl">{member.boardId.name || `Board ${index + 1}`}</p>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              filteredDrawings.map((drawing, index) => (
                <div key={drawing._id} className="relative border border-gray-300 rounded-lg overflow-hidden">
                  <Link to={`/whiteboard/${drawing._id}`} target="_blank" onClick={() => localStorage.setItem('drawingId', drawing._id)}>
                    <img src={drawing.thumbnail || "/images.jpeg"} alt={`Drawing ${index + 1}`} className="w-full h-32 object-cover" />
                    <div className="flex items-center justify-center mt-2">
                      <p className="text-xl">{drawing.name || `Drawing ${index + 1}`}</p>
                      <button onClick={(e) => { e.preventDefault(); handleDelete(drawing._id); fetchDrawings(); }} className="mr-2 text-gray-600 hover:text-red-600">
                        <FaTrash />
                      </button>
                    </div>
                  </Link>
                </div>
              ))
            )}
          </>
        )}
      </div>

      <div className="mb-12">
        <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 gap-4 mt-4 rounded pb-16">
          {filteredCharts.length === 0 ? (
            <div className="col-span-4 text-center text-gray-500 text-sm">
              No Flowchart Found
            </div>
          ) : (
            filteredCharts.map((chart, index) => (
              <div key={chart._id} className="relative border border-gray-300 rounded-lg overflow-hidden">
                <Link to={`/flowchart/${chart._id}`} target="_blank" onClick={() => localStorage.setItem('chartId', chart._id)}>
                  <img src={chart.thumbnail || "/Screenshot (17).png"} alt={`Flowchart ${index + 1}`} className="w-full h-32 object-cover" />
                  <div className="flex items-center justify-center mt-2">
                    <p className="text-xl">{chart.name || `Flowchart ${index + 1}`}</p>
                    <button onClick={(e) => { e.preventDefault(); handleDeleteChart(chart._id); }} className="mr-2 text-gray-600 hover:text-red-600">
                      <FaTrash />
                    </button>
                  </div>
                </Link>

              </div>
            ))
          )}
        </div>
      </div>


      {/* <div className="mb-12">
          <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 gap-4 mt-4 rounded pb-16">
            {filteredCharts.length === 0 ? (
              <div className="col-span-4 text-center text-gray-500 text-sm">
                No Flowchart Found
              </div>
            ) : (
              filteredCharts.map((chart, index) => (
                <div key={chart._id} className="relative border border-gray-300 rounded-lg overflow-hidden">
                  <Link
                    to={`/flowchart/${chart._id}`} target="_blank"
                    onClick={() => localStorage.setItem('chartId', chart._id)}
                    className="flex flex-col items-center"
                  >
                    <img
                      src={chart.thumbnail || "/Screenshot (17).png"}
                      alt={`Flowchart ${index + 1}`}
                      className="w-full h-32 object-cover"
                    />
                    <div className="flex items-center justify-center mt-2">
                      <p className="text-xl">{chart.name || `Flowchart ${index + 1}`}</p>
                      <button
                        onClick={(e) => {
                          e.preventDefault(); 
                          handleDeleteChart(chart._id);
                        }}
                        className="mr-2 text-gray-600 hover:text-red-600"
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </Link>
                </div>
              ))
            )}
          </div>

          <ul className="flex justify-around bg-white shadow-md fixed bottom-0 w-full py-2 md:hidden z-50">
           
          </ul>

        </div> */}

    </>
  );
}

// }

export default CreateBoard;


// let token = JSON.parse(localStorage.getItem('token'))
// useEffect(() => {
//   if (token === null) {
//     setLoad(false)
//     navigate('/login')
//   } else {
//     userProfile()
//       .then(res => {
//         setUser(res.data)
//         setLoad(false)
//       })
//       .catch(err => {
//         localStorage.removeItem("token")
//         localStorage.removeItem("userId")
//         localStorage.removeItem("projectId")
//         navigate('/login')
//         setLoad(false)
//       }
//       )

//   }
// }, [])