import { create } from 'zustand';
import axios from "axios";
import { apiUrl } from '../../Config';

export const useBoardStore = create((set, get) => ({
    currentBoard: null,

    createBoard: async (payload) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(
                ` ${apiUrl}/create`,
                payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
            );
            console.log('Board Created successfully', response);
            return response.data;
        } catch (err) {
            console.error('failed to create board:', err);
            throw err;
        }
    },

    getBoard: async () => {
        const projectId = localStorage.getItem('ProjectId');
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        try {
            const response = await axios.get(`${apiUrl}/getbyuserid/${userId}/${projectId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log('Boaard fetched successfully:', response);
            return response.data;
        } catch (err) {
            console.error('Failed to fetch boards:', err);
            throw err;
        }
    },
    getBoardId: async () => {
        const { currentBoard } = get();
        if (!currentBoard || !currentBoard._id) {
            throw new Error("No current board selected");
        }

        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${apiUrl}/get/${currentBoard._id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (err) {
            console.error('Failed to fetch board:', err);
            throw err;
        }
    },

    getProjectbyId: async () => {
        const { currentBoard } = get();
        if (!currentBoard || !currentBoard._id) {
            throw new Error("No current board selected");
        }

        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${apiUrl}/getProjectbyboard/${currentBoard._id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (err) {
            console.error('Failed to fetch board:', err);
            throw err;
        }
    },
    searchBoard: async (searchTerm) => {
        const token = localStorage.getItem('token');
        const projectId = localStorage.getItem('ProjectId');
        try {
            const response = await axios.get(`${apiUrl}/search/${projectId}?name=${encodeURIComponent(searchTerm)}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            console.log(response.data);
            return response.data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    },

    updateBoard: async (payload) => {
        const { currentBoard } = get();
        const token = localStorage.getItem('token');

        try {
            const response = await axios.put(
                `${apiUrl}/updateShapes/${currentBoard._id}`,
                payload, // send payload directly, not inside an object like { body: payload }
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (err) {
            console.error('Failed to update board:', err);
            throw err;
        }
    },
    updateBoardname: async (payload) => {
        const { currentBoard } = get();
        const token = localStorage.getItem('token');

        try {
            const response = await axios.put(
                `${apiUrl}/updatename/${currentBoard._id}`,
                payload, // send payload directly, not inside an object like { body: payload }
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (err) {
            console.error('Failed to update board:', err);
            throw err;
        }
    },
    addshapes: async (payload) => {
        const { currentBoard } = get();
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post(
                `${apiUrl}/shapes/${currentBoard._id}`,
                payload, // send payload directly, not inside an object like { body: payload }
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (err) {
            console.error('Failed to update board:', err);
            throw err;
        }
    },
    deleteBoard: async (boardId) => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.delete(
                `${apiUrl}/delete/${boardId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (err) {
            console.error('Failed to delete board:', err.response ? err.response.data : err.message);
            throw err;
        }
    },
    updateProject: async (payload) => {
        const { currentBoard } = get();
        const token = localStorage.getItem('token');
        try {
            const response = await axios.put(`${apiUrl}/updateprojectId/${currentBoard._id}`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (err) {
            console.error('failed to update projectid', err);
            throw err;
        }
    },
    undoDrawing: async () => {
        const { currentBoard } = get(); 
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(
                `${apiUrl}/undo/${currentBoard._id}`,
                {}, 
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log('Undo successful:', response.data);
            return response.data
            // You can update state or do something with the response data here
        } catch (err) {
            console.error('Error performing undo:', err);
           return err;
        }
    },
    
    redoDrawing: async () => {
        const { currentBoard } = get();
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(
                `${apiUrl}/redo/${currentBoard._id}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log('redo successful:', response.data);
            return response.data;
        } catch (err) {
            console.error('Error performing redo:', err);
            return err;
        }
    },



    setCurrentBoard: (board) => set({ currentBoard: board })
}))
