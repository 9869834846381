import React, { useState, useEffect } from 'react'
import { useBoardStore } from '../Store/BoardStore/BoardStore'
import { useMemberStore } from '../Store/MemberStore/MemberStore';
import { useParams } from 'react-router-dom';
import Whiteboard from './Whiteboard'

const Board = () => {
    const { id } = useParams();
    const [access, setAccess] = useState(false);
    const [boards, setBoards] = useState([]);
    const [name, setName] = useState([]);
    const [drawing, setDrawing] = useState();
    const [selected, setSelected] = useState("");
    const [loading, setLoading] = useState(true)
    const { getMode } = useMemberStore();
    const { getBoardId, setCurrentBoard } = useBoardStore();



    const fetchBoard = async () => {
        try {
            const response = await getBoardId();
            // console.log('Board response:', response);

            if (response) {
                console.log(response);
                const ID = response.projectId
                setBoards(response);
                setName(response.name);
                setDrawing(response.user)
                setSelected(ID);

            } else {
                console.error('Board response is null or undefined.');
            }
        } catch (error) {
            console.error('Error fetching board:', error);
        }
    };
    // console.log(boards)

    useEffect(() => {
        if (id) {
            setCurrentBoard({ _id: id });
            fetchBoard();
        }
    }, [id, setCurrentBoard]);

    const user = Number(localStorage.getItem('userId'))
   
    useEffect(() => {
        if (drawing) {
            // Check if the user matches the drawing owner
            if (drawing === user) {
                setAccess(true);
                setLoading(false)

            } else {
                getMode()
                    .then(res => {
                        console.log(res);
                        const message = res.message || '';
                        // Set access based on the mode
                        if (message === 'view only') {
                            setAccess(false); // No access in view-only mode
                        } else if (message === 'edit') {
                            setAccess(true); // Full access in edit mode
                        }
                        setLoading(false)
                    })
                    .catch(err => {
                        console.error('Error fetching mode:', err);
                        setAccess(false); // Default to no access on error
                    });
            }
        }
    }, [drawing]);
    if (loading) {
        return (
            <div className="loader-container">
                <div className="spinner"></div>
                <p className="loading-message">Loading...</p>
            </div>
        )
    } else {
        return (
            <Whiteboard boards={boards} fetchMainBoard={fetchBoard} setBoards={setBoards} id={id} drawing={drawing} name={name} setname={setName} access={access} setAccess={setAccess} loading={loading} setLoading={setLoading} />
        )
    }
}

export default Board
